import React, { ReactElement } from "react";
import MainBanner from "@components/creditcardprocessorrates/mainBanner";
import CreditCardProcessorWrapper from "@components/layouts/creditCardProcessorWrapper";
import { GetServerSideProps } from "next";
import dynamic from "next/dynamic";
import { getPageProps } from "@services/initial-calls";
import { useDomainContext } from "@hooks/useDomainContext";
import OffersList from "@components/shared/offersList";

const WhyUs = dynamic(
    () => import("@components/creditcardprocessorrates/whyUs"),
);

export default function CreditCardProcessorRatesHome(): ReactElement {
    const { domain } = useDomainContext();

    return (
        <CreditCardProcessorWrapper>
            <MainBanner />
            <section>
                <OffersList offers={domain?.defaultOffers} />
            </section>
            <WhyUs />
        </CreditCardProcessorWrapper>
    );
}

export const getServerSideProps: GetServerSideProps = async (context) => {
    return await getPageProps(context, "home");
};
